import React from 'react'
import OngoingOrderDetails from '../../components/Homemade/OngoingOrderPage/OngoingOrderDetails'

import generatePagePropsonBuildTime from '../../helpers/generatePagePropsOnBuildTime'


const OrderDetails = (props) => {
    console.log(props)
    console.log(generatePagePropsonBuildTime(props, {
        order: {}, 
        id: 0
    }))
    return <OngoingOrderDetails {...props}/>
}

export default OrderDetails
